<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="loading" class="mb-4">
        <v-col cols="12" lg="4" v-for="i in 3" :key="i">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-col>
        
      </v-row>

      <v-row v-else>
        <v-col cols="12" lg="4">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <div class="d-flex justify-space-between align-center">
                <div>
                  <v-card-title class="success--text">
                    {{ CurrencyFormatting(totals.total_accounts) }} DZD
                  </v-card-title>
                  <v-card-subtitle class="text-no-wrap">
                    Total des balances comptes
                  </v-card-subtitle>
                </div>

                <div>
                  <v-avatar size="70" color="gifty">
                    <v-icon dark size="30">mdi-currency-usd</v-icon>
                  </v-avatar>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <div class="d-flex justify-space-between align-center">
                <div>
                  <v-card-title class="success--text">
                    {{ CurrencyFormatting(totals.total_consommation) }} DZD
                  </v-card-title>
                  <v-card-subtitle class="text-no-wrap">
                    Total consommation
                  </v-card-subtitle>
                </div>

                <div>
                  <v-avatar size="70" color="primary">
                    <v-icon dark size="30">mdi-currency-usd</v-icon>
                  </v-avatar>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4">
          <v-card class="rounded-lg shadow">
            <v-card-text>
              <div class="d-flex justify-space-between align-center">
                <div>
                  <v-card-title class="success--text">
                    {{ CurrencyFormatting(totals.total_consommation) }} DZD
                  </v-card-title>
                  <v-card-subtitle class="text-no-wrap">
                    Total des montants service disponible
                  </v-card-subtitle>
                </div>

                <div>
                  <v-avatar size="70" color="success">
                    <v-icon dark size="30">mdi-currency-usd</v-icon>
                  </v-avatar>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-skeleton-loader
        type="table"
        v-if="loading"
        class="mb-3"
      ></v-skeleton-loader>
      <v-row v-else>
        <v-col cols="12">
          <v-alert icon="mdi-shield-lock-outline" prominent text type="error">
            Un service est en rouge si le pourcentage du montant service
            disponible est inférieur au pourcentage de sa consommation
            habituelle.
          </v-alert>

          <v-alert icon="mdi-information" prominent text type="info">
            Les services de type voucher avec génération dynamique peuvent être disponible même s'il n'y a pas un montant service disponible. 
            (Ex. generation des vouchers e-commerce)
          </v-alert>

          <v-sheet :rounded="'lg'">
            <div v-if="services.length > 0">
              <v-simple-table
                fixed-header
                height="600px"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="font-weight-bold">Service</th>
                      <th class="font-weight-bold text-center">
                        Montant disponible
                      </th>

                      <th class="font-weight-bold text-center">
                        Balance Utilisateurs dans le service
                      </th>
                      <th class="font-weight-bold text-center">
                        Pourcentage (Disponible / Balance Utilisateurs)
                      </th>
                      <th class="font-weight-bold text-center">
                        Transactions service (%)
                      </th>

                      <th class="font-weight-bold text-center">
                        Transactions services
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in services"
                      :key="item.id"
                      style="redColor(item)"
                    >
                      <td class="font-weight-bold" style="width: 50px">
                        <v-img :src="getImageLink(item.image)" width="50" />
                      </td>
                      <td class="font-weight-bold" :style="redColor(item)">
                        {{ item.name }}
                      </td>

                      <td
                        class="font-weight-bold pink--text text-center pr-4"
                        :style="redColor(item)"
                      >
                        {{ CurrencyFormatting(item.available_amount) }} DZD
                      </td>
                      <td
                        class="font-weight-bold pink--text text-center pr-4"
                        :style="redColor(item)"
                      >
                        {{ CurrencyFormatting(item.accounts_balances) }} DZD
                      </td>

                      <td
                        class="font-weight-bold text-center"
                        style="background-color: #ff555522"
                        v-if="item.percent <= item.consomation.percent"
                      >
                        <v-btn class="red--text rounded-lg" elevation="0">
                          
                          {{
                            getPercent(
                              item.available_amount,
                              item.accounts_balances
                            )
                          }}
                          %</v-btn
                        >
                      </td>
                      <td v-else class="font-weight-bold text-center">
                        <v-btn class="success--text rounded-lg" elevation="0">
                          {{ item.percent }}
                          %
                        </v-btn>
                      </td>
                      <td
                        class="font-weight-bold pink--text text-center pr-4"
                        :style="redColor(item)"
                      >
                        <v-btn class="blue--text rounded-lg" elevation="0">
                          {{ item.consomation.percent }}
                          %
                        </v-btn>
                      </td>

                      <td
                        class="font-weight-bold pink--text text-center pr-4"
                        :style="redColor(item)"
                      >
                        {{
                          CurrencyFormatting(
                            item.consomation.conommation_amount
                          )
                        }}
                        DZD
                      </td>
                    </tr>

                    <tr>
                      <td colspan="6" class="font-weight-bold text-right">
                        Total :
                      </td>
                      <td class="font-weight-bold pink--text text-center pr-4">
                        {{ CurrencyFormatting(services[0].consomation.total) }}
                        DZD
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else>
              <div class="text-center">
                <v-avatar tile size="200">
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
                <h3 class="text--primary d-block mb-2">OOPS !</h3>
                <p>Aucun enregistrement correspondant trouvé.</p>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

      <AnalyseServices />
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";
import AnalyseServices from "./AnalyseServices.vue";

export default {
  components: { AnalyseServices },
  data() {
    return {
      loading: true,
      services: {},
    };
  },

  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("/service/balance_services")
        .then((res) => {
          console.log(res);
          this.services = res.data.balances;
          this.totals = res.data.totals;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    redColor(item) {
      if (item.percent <= item.consomation.percent) {
        return "background-color: #ff555522";
      }

      return "";
    },
  },

  computed: {
    // services() {
    //   return this.$store.getters.getServices;
    // },
  },

  mounted() {
    // this.$store.dispatch("fetchServices", {
    //   filter: {
    //     size: 10000
    //   },
    // });

    this.fetchData();
  },
};
</script>
