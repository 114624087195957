<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-sheet :rounded="'lg'">
          <v-card-title class="align-start d-flex">
            <v-avatar class="elevation-3" color="primary" size="38">
              <v-icon color="white"> mdi-update </v-icon>
            </v-avatar>
            <span class="font-weight-bold text-center ml-3">
              Analyse Par service</span
            >
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="align-start mt-0">
            <v-skeleton-loader type="table" v-if="loading" />

            <div v-else>
              <div v-if="data.transactionsByService.length > 0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th class="font-weight-bold">Service</th>
                        <th class="font-weight-bold text-center">Montant</th>
                        <th class="font-weight-bold text-center">
                          Pourcentage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in data.transactionsByService"
                        :key="item.id"
                      >
                        <td
                          v-if="item.service"
                          class="font-weight-bold"
                          style="width: 50px"
                        >
                          <v-img
                            :src="getImageLink(item.service.image)"
                            width="50"
                          />
                        </td>
                        <td v-else style="width: 50px"></td>
                        <td v-if="item.service" class="font-weight-bold">
                          {{ item.service.name }}
                        </td>
                        <td class="font-weight-bold" v-else>Unnamed Service</td>
                        <td class="font-weight-bold pink--text text-right pr-4">
                          {{ CurrencyFormatting(item.service_amount) }} DZD
                        </td>

                        <td class="font-weight-bold text-center">
                          {{ getPercent(item.service_amount, data.total) }} %
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-else>
                <div class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="6">
        <v-sheet :rounded="'lg'">
          <v-card-title class="align-start d-flex">
            <v-avatar class="elevation-3" color="primary" size="38">
              <v-icon color="white"> mdi-update </v-icon>
            </v-avatar>
            <span class="font-weight-bold text-center ml-3">
              Analyse Par Type Service</span
            >
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="align-start mt-0">
            <v-skeleton-loader type="table" v-if="loading" />

            <div v-else>
              <div v-if="data.transactionsByTypeService.length > 0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="font-weight-bold">Type</th>
                        <th class="font-weight-bold text-center">Montant</th>
                        <th class="font-weight-bold text-center">
                          Pourcentage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in data.transactionsByTypeService"
                        :key="item.id"
                      >
                        <td class="font-weight-bold">
                          {{ item.name }}
                        </td>
                        <td class="font-weight-bold pink--text text-right pr-4">
                          {{ CurrencyFormatting(item.amount) }} DZD
                        </td>

                        <td class="font-weight-bold text-center">
                          {{ getPercent(item.amount, data.total) }} %
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-else>
                <div class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      loading: true,
      data: {},
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.post("/transaction/analyse_services")
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>